export const removeHyphen = (str: string) => {
  return str.replace(/-/g, '');
};

// 한글 자음 추출 함수
export const getChosung = (text: string) => {
  const CHOSUNG_LIST = [
    'ㄱ',
    'ㄲ',
    'ㄴ',
    'ㄷ',
    'ㄸ',
    'ㄹ',
    'ㅁ',
    'ㅂ',
    'ㅃ',
    'ㅅ',
    'ㅆ',
    'ㅇ',
    'ㅈ',
    'ㅉ',
    'ㅊ',
    'ㅋ',
    'ㅌ',
    'ㅍ',
    'ㅎ',
  ];
  const HANGUL_START = 0xac00;
  const CHOSUNG_INTERVAL = 588;

  return text
    .split('')
    .map((char) => {
      const charCode = char.charCodeAt(0) - HANGUL_START;
      if (charCode >= 0 && charCode < 11172) {
        return CHOSUNG_LIST[Math.floor(charCode / CHOSUNG_INTERVAL)];
      }
      return char;
    })
    .join('');
};

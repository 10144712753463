import { IconName } from '@components/admin/@shared/atoms';

// path
const ADMIN_GUEST_PATH = {
  signup: '/signup',
  signupResult: '/signup/result',
  login: '/login',
  findEmail: '/find-email',
  findPassword: '/find-password',
};

const ADMIN_MY_PAGE_PATH = {
  index: '/my',
  get account() {
    return `${this.index}/account`;
  },
  get authority() {
    return `${this.index}/authority`;
  },
};

const ADMIN_DASHBOARD_PATH = {
  index: 'dashboard',
  list: 'list',
};

const ADMIN_PRODUCT_IMAGE_PATH = {
  index: 'product-image',
  list: 'list',
  upload: 'upload',
};

const ADMIN_CARE_LABEL_IMAGE_PATH = {
  index: 'care-label-image',
  list: 'list',
  upload: 'upload',
};

const ADMIN_DPP_INFO_PATH = {
  index: 'dpp',
  list: 'list',
  // detail: ':productId',
  detail: ':no',
  excelUpload: 'upload-excel',
  upload: 'upload',
  // update: 'update/:productId',
  update: 'update/:no',
};

const ADMIN_CARE_LABEL_PATH = {
  index: 'care-label',
  create: 'new',
  design: 'design',
  orders: 'orders',
  payment: 'payment',
  paymentSuccess: 'payment/success',
  paymentFail: 'payment/fail',
};

type AdminBrandPath = {
  index: string;
  shippingAddress: string; // 추가
  managers: string;
  list: string;
  detail: string;
  upload: string;
  update: string;
};

const ADMIN_BRAND_PATH: AdminBrandPath = {
  index: 'brand',
  shippingAddress: 'shipping-address',
  managers: 'managers',
  list: 'list',
  detail: ':brandName',
  upload: 'upload',
  update: 'update/:brandName',
};

const ADMIN_ORDER_PATH = {
  index: 'order',
  list: 'list',
  detail: ':orderId',
};

const ADMIN_TERMS_PATH = {
  index: 'terms',
  privacyPolicy: 'privacy-policy',
  service: 'service',
  refund: 'refund',
};

/**
 * @todo path 구조 전체 수정 필요
 */
export const ADMIN_PAGE_PATH = {
  landing: '/',
  terms: ADMIN_TERMS_PATH,
  index: '/admin',
  guest: ADMIN_GUEST_PATH,
  mypage: ADMIN_MY_PAGE_PATH,
  dashboard: ADMIN_DASHBOARD_PATH,
  productImage: ADMIN_PRODUCT_IMAGE_PATH,
  careLabelImage: ADMIN_CARE_LABEL_IMAGE_PATH,
  dppInfo: ADMIN_DPP_INFO_PATH,
  careLabel: ADMIN_CARE_LABEL_PATH,
  brand: ADMIN_BRAND_PATH,
  order: ADMIN_ORDER_PATH,
};

export const PARTNER_PAGE_PATH = {
  index: '/partner',
  list: 'list',
} as const;

// export const SUPER_ADMIN_NAV_LIST: Nav[] = [
//     {
//       id: 0,
//       path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dashboard.index}`,
//       label: 'nav.dashboard',

//     },
//   {
//     id: 1,
//     path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.brand.index}`,
//     label: 'nav.brandManagement',
//     iconName: 'Heart',
//   },
//   {
//     id: 2,
//     path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.productImage.index}`,
//     label: 'nav.productImage',
//     iconName: 'Clothing',
//   },
//   {
//     id: 3,
//     path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabelImage.index}`,
//     label: 'nav.careLabelImage',
//     iconName: 'Label',
//   },
//   {
//     id: 4,
//     path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dppInfo.index}`,
//     label: 'nav.dppInfo',
//     iconName: 'DigitalLabel',
//   },
// ];

const PRICE_LIST_URL =
  'https://img.careid.xyz/default/document/%EC%BC%80%EC%96%B4%EB%9D%BC%EB%B2%A8+%EB%8B%A8%EA%B0%80%ED%91%9C20250206.xlsx';

export type Nav = {
  id: number;
  label: string;
  children: NavChildren[];
};

export type NavChildren = {
  id: number;
  path: string;
  label: string;
  iconName?: IconName;
};

export const BRAND_ADMIN_NAV_LIST: Nav[] = [
  {
    id: 1,
    label: '브랜드 관리',
    children: [
      {
        id: 101,
        path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.brand.index}`,
        label: '브랜드 정보',
        iconName: 'Heart',
      },
      {
        id: 102,
        path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.brand.index}/${ADMIN_PAGE_PATH.brand.shippingAddress}`, // 새로 만들어서 추가 필요
        label: '배송지 정보',
        iconName: 'PaymentShipping',
      },
      // {
      //   id: 103,
      //   path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.brand.index}`, // 수정 필요
      //   label: '담당자 정보',
      //   iconName: 'ManagerInfo',
      // },
    ],
  },
  {
    id: 2,
    label: '케어라벨',
    children: [
      {
        id: 201,
        path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}/${ADMIN_PAGE_PATH.careLabel.create}`,
        label: '라벨 등록',
        iconName: 'CareLabelCreate',
      },
      {
        id: 202,
        path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}`,
        label: '라벨 관리',
        iconName: 'CareLabelList',
      },
      {
        id: 203,
        path: PRICE_LIST_URL,
        label: '라벨 단가표',
        iconName: 'PriceList',
      },
      {
        id: 204,
        path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dppInfo.index}/${ADMIN_PAGE_PATH.dppInfo.upload}`,
        label: '디지털 ID 등록',
        iconName: 'DigitalLabelCreate',
      },
      {
        id: 205,
        path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dppInfo.index}`,
        label: '디지털 ID 관리',
        iconName: 'DigitalLabelList',
      },
    ],
  },
  // {
  //   id: 3,
  //   label: '디지털라벨',
  //   children: [
  //     {
  //       id: 301,
  //       path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dppInfo.index}/${ADMIN_PAGE_PATH.dppInfo.upload}`,
  //       label: '신규 등록',
  //       iconName: 'DigitalLabelCreate',
  //     },
  //     {
  //       id: 302,
  //       path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dppInfo.index}`,
  //       label: '목록',
  //       iconName: 'DigitalLabelList',
  //     },
  //   ],
  // },
  {
    id: 4,
    label: '주문 내역',
    children: [
      {
        id: 401,
        path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.order.index}/${ADMIN_PAGE_PATH.order.list}`,
        label: '주문 내역',
        iconName: 'OrderList',
      },
    ],
  },
];

export const BRAND_MANAGER_NAV_LIST: Nav[] = [
  // ...BRAND_ADMIN_NAV_LIST.slice(0, -1),
  ...BRAND_ADMIN_NAV_LIST,
];

import { ToastContainer, cssTransition } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

export default function Toast() {
  return (
    <StyledToastContainer
      limit={1}
      autoClose={3000}
      hideProgressBar={true}
      closeOnClick
      position="bottom-center"
      transition={FadeInOutTransition}
      enableMultiContainer
    />
  );
}

const FadeInOutTransition = cssTransition({
  enter: 'animate__animated animate__fadeIn animate__faster',
  exit: 'animate__animated animate__fadeOut animate__faster',
});

const StyledToastContainer = styled(ToastContainer as any)`
  z-index: 9999;

  /* Toast Animation Duration */
  --animate-duration: 300ms;
  /* Color Set */
  --toastify-color-white: ${(props) => props.theme.colors.white};
  --toastify-color-info: ${(props) => props.theme.colors.gray300};
  --toastify-color-success: ${(props) => props.theme.colors.stateSuccess};
  --toastify-color-warning: ${(props) => props.theme.colors.yellow};
  --toastify-color-error: #c53622;
  /* Icon Color */
  --toastify-icon-color-info: var(--toastify-color-white);
  --toastify-icon-color-success: var(--toastify-color-white);
  --toastify-icon-color-warning: var(--toastify-color-white);
  --toastify-icon-color-error: var(--toastify-color-white);
  /* Progress Bar Color */
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  @media screen and (min-width: 768px) {
    &&&.Toastify__toast-container {
      max-width: 500px;
    }

    .Toastify__toast {
      padding: ${(props) =>
        `${props.theme.spaces.md} ${props.theme.spaces.md} ${props.theme.spaces.xl}`};
    }
  }

  &&&.Toastify__toast-container {
    background: none;
    box-shadow: none;
    width: 100%;
    padding: ${(props) => props.theme.spaces.md};
    padding-bottom: 52px;
  }

  .Toastify__toast-container--top-center {
    top: 200px; /* 원하는 만큼 값 조절 */
  }

  .Toastify__toast-container--bottom-center {
  }

  /** Used to position the icon **/
  .Toastify__toast-icon {
    margin: 0;
    position: relative;
    top: 1px;
  }

  .Toastify__toast {
    padding: 16px;
    padding-left: 18px;
    font-size: 16px;
    color: ${(props) => props.theme.colors.white};
    background-color: var(--toastify-color-info);
    border-radius: 8px;
    border: none;
    box-shadow: none;
    min-height: auto;
  }

  .Toastify__toast.Toastify__toast--error {
    background-color: var(--toastify-color-error);
  }

  .Toastify__toast.Toastify__toast--success {
    background-color: var(--toastify-color-success);
  }

  .Toastify__toast-body {
    /* text-align: center; */
    white-space: pre-line;
    line-height: ${(props) => props.theme.fonts.lineHeight.normal};
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;

    /* 텍스트 */
    & div:last-child {
      padding: 0 ${(props) => props.theme.spaces.md};
    }
  }

  /* 프로그레스 바 */
  .Toastify__progress-bar {
    opacity: 1;
  }

  /* 닫기 버튼 */
  .Toastify__close-button {
    opacity: 1;
    width: 16px;
    height: 16px;
  }

  .Toastify__close-button > svg {
    fill: ${(props) => props.theme.colors.white};
  }

  /* 아이콘 */
  .Toastify__toast-icon svg {
    width: 16px;
    height: 16px;
  }
`;

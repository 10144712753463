import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, LogoButton } from '@components/admin/@shared/atoms';
import { useAtom } from 'jotai';
import {
  accessTokenAtomWithPersistence,
  emailAtomWithPersistence,
  roleAtomWithPersistence,
} from '@store/login';
import { ADMIN_GUEST_PATH, ADMIN_PAGE_PATH } from '@lib/constants/admin';
import { useLogout } from '@lib/hooks/logout/useLogout';
import { useState } from 'react';
import { useModal } from '@lib/hooks';
import { LandingModal } from './LandingModal/LandingModal';
import { media } from '@lib/styles/breakpoints';
import { PARTNER_PAGE_PATH } from '@lib/constants/admin/route';

export default function Header() {
  const navigate = useNavigate();
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);
  const [role] = useAtom(roleAtomWithPersistence);
  const [email] = useAtom(emailAtomWithPersistence);
  const { handleLogout } = useLogout({ path: ADMIN_PAGE_PATH.landing });
  const [menuOpen, setMenuOpen] = useState(false);
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  // const handlePrimaryButtonClick = () => {
  //   if (role === 'user') {
  //     toast.info(
  //       '브랜드를 등록하거나 매니저 권한을 승인받은 후 서비스를 이용할 수 있습니다.'
  //     );
  //     return;
  //   }

  //   const path = accessToken
  //     ? `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}`
  //     : `${ADMIN_GUEST_PATH.login}`;

  //   navigate(path);
  // };

  // const handlePrimaryButtonClick = () => {
  //   if (accessToken) {
  //     handleModalOpen();
  //   } else {
  //     navigate(ADMIN_GUEST_PATH.login);
  //   }
  // };

  const handlePrimaryButtonClick = () => {
    if (!accessToken) {
      navigate(ADMIN_GUEST_PATH.login);
      return;
    }

    if (role === 'partner') {
      navigate(`${PARTNER_PAGE_PATH.index}/${PARTNER_PAGE_PATH.list}`);
      return;
    }

    if (role === 'brand') {
      handleModalOpen();
      return;
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavClick = () => {
    setMenuOpen(false);
  };

  return (
    <>
      {isModalOpen && <LandingModal onClose={handleModalClose} />}
      <Wrapper $isOpen={menuOpen}>
        <Container>
          <LeftSideContainer>
            <LogoButton />
            <nav>
              <NavList $isOpen={menuOpen}>
                <NavItem>
                  <Link to="/care-label" onClick={handleNavClick}>
                    케어라벨
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/digital-label" onClick={handleNavClick}>
                    디지털 ID
                  </Link>
                </NavItem>
                <NavItem>
                  <a
                    href="https://careid.center"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleNavClick}
                  >
                    회사 소개
                  </a>
                </NavItem>
                {/* <NavItem>
                <Link to="#">문의하기</Link>
              </NavItem> */}
              </NavList>
            </nav>
          </LeftSideContainer>
          <RightSideContainer>
            {accessToken && email ? (
              <MyPageLink
                to={ADMIN_PAGE_PATH.mypage.index}
                $isLogin={!!accessToken}
              >
                <Icon name="UserProfile" width={24} height={24} />
                <Email>{email}</Email>
              </MyPageLink>
            ) : null}

            {accessToken && (
              <LogoutButton type="button" onClick={handleLogout}>
                로그아웃
              </LogoutButton>
            )}
            <Button
              padding="10px 20px"
              fontSize="14px"
              fontWeight="400"
              height="100%"
              type="button"
              onClick={handlePrimaryButtonClick}
            >
              {accessToken ? '서비스 시작' : '로그인'}
            </Button>
            <HamburgerMenu onClick={toggleMenu} $isOpen={menuOpen}>
              <span></span>
              <span></span>
            </HamburgerMenu>
          </RightSideContainer>
        </Container>
        {menuOpen && <Overlay onClick={toggleMenu} />}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div<{ $isOpen?: boolean }>`
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ $isOpen, theme }) =>
    $isOpen ? 'none' : `1px solid ${theme.colors.gray100}`};
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 40px;

  ${media.tablet} {
    padding: 0 20px;
  }

  ${media.mobile} {
    padding: 10px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface NavListProps {
  $isOpen: boolean;
}

const NavList = styled.ul<NavListProps>`
  display: flex;
  align-items: center;
  gap: 12px;

  ${media.tablet} {
    gap: 8px;
  }

  ${media.mobile} {
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 20px;
    z-index: 1000;
    align-items: center;
  }
`;

const LeftSideContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 34px;

  ${media.tablet} {
    gap: 16px;
  }
`;

const NavItem = styled.li`
  padding: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border-radius: 8px;

  a {
    color: #000;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    background-color: #f8f8f8;

    a {
      color: #666;
    }
  }

  ${media.tablet} {
    font-size: 14px;
  }

  ${media.mobile} {
    font-size: 16px;
  }
`;

const RightSideContainer = styled.div`
  // height: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  gap: 4px;

  ${media.mobile} {
    > *:not(:last-child) {
      display: none;
    }
    width: 44px;
    justify-content: center;
  }
`;

interface MyPageLinkProps {
  $isLogin: boolean;
}

const MyPageLink = styled(Link)<MyPageLinkProps>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const Email = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray900};

  ${media.tablet} {
    font-size: 14px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gray600};
  }
`;

const LogoutButton = styled.button`
  width: fit-content;
  height: 100%;
  border-radius: 0;
  background: none;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray400};
  padding: 6px;
  word-break: keep-all;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const HamburgerMenu = styled.div<{ $isOpen: boolean }>`
  display: none; // 모바일 버전에서만 보이도록 함
  cursor: pointer;
  width: 20px;
  height: 44px;
  position: relative;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #999;
    transition: 0.3s ease-in-out;

    &:nth-child(1) {
      top: 50%;
      transform: ${({ $isOpen }) =>
        $isOpen ? 'translateY(-50%) rotate(45deg)' : 'translateY(-5px)'};
    }

    &:nth-child(2) {
      top: 50%;
      transform: ${({ $isOpen }) =>
        $isOpen ? 'translateY(-50%) rotate(-45deg)' : 'translateY(5px)'};
    }
  }

  ${media.mobile} {
    display: block;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: calc(100vh - 64px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

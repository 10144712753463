const VIDEO_STORAGE_BASE_URL = 'https://img.careid.xyz/default/video';

// 랜딩페이지 비디오
export const HOME_VIDEO_URL = `${VIDEO_STORAGE_BASE_URL}/xyz_Home.mp4`;
export const HOME_MOBILE_VIDEO_URL = `${VIDEO_STORAGE_BASE_URL}/xyz_Home_Mobile.mp4`;

/**
 * Thug Club
 */
// Thug Club 로고 영상
export const THUG_CLUB_LOGO_VIDEO_URL = `${VIDEO_STORAGE_BASE_URL}/thugclub-logo-video.gif`;

// admin Banner 비디오
export const DPP_BANNER_VIDEO_URL = `${VIDEO_STORAGE_BASE_URL}/Banner_Digital_Label_SaaS.mp4`;
export const CARE_LABEL_BANNER_VIDEO_URL = `${VIDEO_STORAGE_BASE_URL}/Banner_Care_Label_SaaS.mp4`;

// partner Banner 비디오
export const PARTNER_BANNER_VIDEO_URL = `${VIDEO_STORAGE_BASE_URL}/Banner_Partner_SaaS.mp4`;

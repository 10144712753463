import { minimalScrollbar } from '@lib/styles/scrollbarStyles';
import { Children, CSSProperties, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

interface ListTableProps extends CSSProperties {
  children: ReactNode;
}

export function ListTable({ children, ...styles }: ListTableProps) {
  return <ListTableContainer style={styles}>{children}</ListTableContainer>;
}

function Rows({ children }: PropsWithChildren) {
  return (
    <RowsContainer $hasScroll={Children.count(children) > 10}>
      {children}
    </RowsContainer>
  );
}

interface NameRowProps extends CSSProperties {
  count?: number;
  children: ReactNode;
  hideBorder?: boolean;
}

function NameRow({ children, count, hideBorder, ...styles }: NameRowProps) {
  return (
    <NameRowContainer $count={count} $hideBorder={hideBorder} style={styles}>
      {children}
    </NameRowContainer>
  );
}

interface RowProps extends NameRowProps {
  isSelected?: boolean;
  onClick?: () => void;
}

function Row({ children, count, onClick, isSelected, ...styles }: RowProps) {
  return (
    <RowContainer
      onClick={onClick}
      style={styles}
      $count={count}
      $isClickable={!!onClick}
      $isSelected={isSelected}
    >
      {children}
    </RowContainer>
  );
}

interface RowImageContainerProps extends CSSProperties {
  src: string;
}

function ImageContainerInRow({ src, ...styles }: RowImageContainerProps) {
  return (
    <ImageContainerInRowContainer style={styles}>
      <img src={src} alt="image" />
    </ImageContainerInRowContainer>
  );
}

const NO_RESULT_MESSAGE = '검색 결과가 없습니다. 다시 검색해주세요.';

interface NoResultProps {
  message?: string;
}

function NoResult({ message }: NoResultProps) {
  return <NoResultContainer>{message || NO_RESULT_MESSAGE}</NoResultContainer>;
}

export default Object.assign(ListTable, {
  NameRow,
  Rows,
  Row,
  ImageContainerInRow,
  NoResult,
});

const ListTableContainer = styled.div`
  margin: ${({ theme }) => theme.spaces.lg} 0;
  padding: ${({ theme }) => `${theme.spaces.xxl} ${theme.spaces.xxxl}`};
  min-width: 480px;
  min-height: 185px;
  text-align: left;
  word-break: break-all;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  background-color: ${({ theme }) => theme.colors.white};

  max-height: 100%;
  overflow-y: auto;

  ${minimalScrollbar}
`;

interface RowsContainerProps {
  $hasScroll?: boolean;
}

const RowsContainer = styled.div<RowsContainerProps>`
  padding-top: ${(props) => props.theme.spaces.xxs};
  width: 100%;

  ${(props) =>
    props.$hasScroll &&
    `
      max-height: 620px;
      overflow-y: auto;
    `}
`;

interface NameRowContainerProps {
  $count?: number;
  $hideBorder?: boolean;
}

const NameRowContainer = styled.div<NameRowContainerProps>`
  padding: 20px 12px;
  width: 100%;
  display: grid;
  grid-template-columns: 56px repeat(${({ $count }) => $count || 5}, 1fr);
  gap: ${(props) => props.theme.spaces.xxs};
  font-size: ${(props) => props.theme.fonts.size.lg};
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  word-break: keep-all;
  border-bottom: ${({ theme, $hideBorder }) =>
    $hideBorder ? 'none' : `1px solid ${theme.colors.gray100}`};

  & > * {
    display: flex;
    align-items: center;
  }
`;

interface RowContainerProps {
  $count?: number;
  $isSelected?: boolean;
  $isClickable?: boolean;
}

const RowContainer = styled.div<RowContainerProps>`
  padding: 10px 12px;
  width: 100%;
  display: grid;
  grid-template-columns: 56px repeat(${({ $count }) => $count || 5}, 1fr);
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxs};
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
  z-index: 1;

  ${({ $isSelected, theme }) =>
    $isSelected
      ? `
      background-color: ${theme.colors.gray100};
    `
      : `
      &:hover {
        background-color: ${theme.colors.gray25};
      }
    `}

  & > * {
    display: flex;
    align-items: center;
  }
`;

const ImageContainerInRowContainer = styled.div`
  padding: 4px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  overflow: hidden;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.gray600};
`;

import {
  LANGUAGE_ENGLISH_ICON_URL,
  LANGUAGE_KOREAN_ICON_URL,
} from '@lib/constants/iconUrls';

// language
export const LANGUAGES = [
  {
    name: 'ko',
    label: '한국어',
    imgUrl: LANGUAGE_KOREAN_ICON_URL,
  },
  {
    name: 'en',
    label: 'English',
    imgUrl: LANGUAGE_ENGLISH_ICON_URL,
  },
];

// path
const USER_PRODUCT_PATH = {
  index: 'product',
  main: ':productId',
  carbon: ':productId/carbon',
  brand: ':productId/brand',
  setting: ':productId/setting',
  dppInfo: ':productId/dpp',
  freePickUpService: ':productId/pickup',
  repairService: ':productId/repair',
  // Thug Club temp
  certificate: ':productId/certificate',
};

export const USER_PAGE_PATH = {
  index: '/',
  product: USER_PRODUCT_PATH,
};

export const USER_NAV_LIST = [
  {
    id: 0,
    path: '',
    labelKey: 'Product',
  },
  {
    id: 1,
    path: 'carbon',
    labelKey: 'Carbon',
  },
  {
    id: 2,
    path: 'dpp',
    labelKey: 'DPP',
  },
  {
    id: 3,
    path: 'brand',
    labelKey: 'Brand',
  },
  {
    id: 4,
    path: 'setting',
    labelKey: 'Setting',
  },
];

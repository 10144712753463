import { useEffect, MouseEvent, CSSProperties, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { allowScroll, preventScroll } from '@lib/utils';
import { Icon } from '@components/@shared';

const portalElement = document.getElementById('modal') as HTMLElement;

interface ModalContainerProps extends CSSProperties {
  children: React.ReactNode;
  onCloseModal: () => void;
}

function ModalContainer({
  children,
  onCloseModal,
  ...styles
}: ModalContainerProps) {
  const [scrollY, setScrollY] = useState<string>('0');

  const handleBackgroundClick = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;

    onCloseModal();
  };

  // 모달이 띄워졌을 때 스크롤 방지 (화면 고정)
  useEffect(() => {
    const prevScrollY = preventScroll();
    setScrollY(`${window.scrollY}px`);

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  return createPortal(
    <Wrapper onClick={handleBackgroundClick} $scrollY={scrollY}>
      <Container style={styles}>{children}</Container>
    </Wrapper>,
    portalElement
  );
}

interface ModalTitleContainerProps extends CSSProperties {
  mainTitle: string;
  onClose: () => void;
  closeIconSize?: number;
}

function ModalTitleContainer({
  mainTitle,
  onClose,
  closeIconSize = 36,
  ...styles
}: ModalTitleContainerProps) {
  return (
    <TitleContainer style={styles}>
      <span>{mainTitle}</span>
      <button onClick={onClose}>
        <Icon name="Close" width={closeIconSize} height={closeIconSize} />
      </button>
    </TitleContainer>
  );
}

interface DialogTitleProps extends CSSProperties {
  title: string;
}

function DialogTitle({ title, ...styles }: DialogTitleProps) {
  return <ModalContainerTitle style={styles}>{title}</ModalContainerTitle>;
}

export default Object.assign(ModalContainer, {
  ModalTitleContainer,
  DialogTitle,
});

interface WrapperProps {
  $scrollY: string;
}

interface DialogProps {
  warning?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: ${(props) => props.$scrollY};
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(28, 28, 28, 0.8);
  z-index: 9998;
  cursor: pointer;
  /* padding: 60px 120px; // 임시로 설정 */
`;

const Container = styled.div`
  padding: 16px;
  position: relative;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  overflow: hidden;
  cursor: default;
  z-index: 9999;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 700;
`;

const Dialog = styled.dialog<DialogProps>`
  flex-grow: 1;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  font-size: ${(props) => props.theme.fonts.size.sm};
  text-align: center;
`;

const ModalContainerTitle = styled.h3`
  font-size: ${(props) => props.theme.fonts.size.md};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  margin-bottom: ${(props) => props.theme.spaces.xxxs};
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Button = styled.button`
  padding: 12px 0;
  width: 100%;
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  color: ${(props) => props.theme.colors.gray600};
  background-color: ${(props) => props.theme.colors.gray50};

  &:last-child {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.gray900};
  }
`;

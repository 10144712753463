const IMAGE_STORAGE_BASE_URL = 'https://img.careid.xyz/default/icon';
const IMAGE_STORAGE_BASE_URL_2 = 'https://img.careid.xyz/default/image';

// 로고
export const LOGO_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/logo.svg`;
// export const LOGO_IMAGE_BLACK_URL = `${IMAGE_STORAGE_BASE_URL}/logo-black.png`;
// 새로운 로고
export const LOGO_IMAGE_BLACK_URL = `${IMAGE_STORAGE_BASE_URL}/CAREID-logo-renewal.svg`;

// 메인 페이지 ReduceCarbon 아이콘 이미지
export const UPGRADE_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/upgrade-image.png`;
export const RECYCLE_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/recycle-image.png`;
export const SCISSOR_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/scissor-image.png`;
export const LAPTOP_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/laptop-image.png`;

// DPP 페이지 DppDescription 이미지
export const DPP_DESCRIPTION_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/dpp-web-image.png`;
export const DPP_DESCRIPTION_EN_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/dpp-web-image--en.png`;

export const BRAND_LOGO_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/LOGO+555.svg`;

// 수선 info 모달 이미지
export const REPAIR_INFO_STRING_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-string.png`;
export const REPAIR_INFO_BUTTON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-button.png`;
export const REPAIR_INFO_STITCH_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-stitch.png`;
export const REPAIR_INFO_REDYING_PARTIAL_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-redying-partial.png`;
export const REPAIR_INFO_REDYING_ENTIRE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-redying-entire.png`;
export const REPAIR_INFO_UNIT_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-unit.png`;

// TGDOT 영상
export const TGDOT_VIDEO_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_2.mp4`;
export const TGDOT_VIDEO_THUMB_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_thumb_2.webp`;

// TGDOT 브랜드 소개 이미지
export const TGDOT_MAIN_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot-corp.webp`;
// TGDOT Tony Collection 이미지
export const TGDOT_COLLECTION_TONY_IMAGE_1_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_01.webp`;
export const TGDOT_COLLECTION_TONY_IMAGE_2_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_02.webp`;
export const TGDOT_COLLECTION_TONY_IMAGE_3_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_03.webp`;
export const TGDOT_COLLECTION_TONY_IMAGE_4_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_04.webp`;
// TGDOT Tony Collection 제품 이미지
export const TGDOT_PRODUCT_HOODIE_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_hoodie_BK.webp`;
export const TGDOT_PRODUCT_HOODIE_GR_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_hoodie_GR.webp`;
export const TGDOT_PRODUCT_PANTS_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_pants_BK1.webp`;
export const TGDOT_PRODUCT_PANTS_GR_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_pants_BK2.webp`;
export const TGDOT_PRODUCT_LONG_SLEEVES_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_longsleeves_2.webp`;
export const TGDOT_PRODUCT_LONG_SLEEVES_BG_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_longsleeves_1.webp`;
export const TGDOT_PRODUCT_SHIRTS_JK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_shirts_JK.webp`;
export const TGDOT_PRODUCT_CAP_DEV_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_DEV.webp`;
export const TGDOT_PRODUCT_CAP_AI_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_AI.webp`;
export const TGDOT_PRODUCT_CAP_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_BK.webp`;
export const TGDOT_PRODUCT_CAP_BL_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_BL.webp`;

// 한투 브랜드 소개 이미지
export const KOREA_INVESTMENT_AC_MAIN_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/KIA_main.png`;
// 한투 Collection 이미지
export const KOREA_INVESTMENT_AC_COLLECTION_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/KIA_collection.png`;
// 한투 제품 이미지
export const KOREA_INVESTMENT_AC_PRODUCT_IMAGE_1_URL = `${IMAGE_STORAGE_BASE_URL}/KIA_product_01_2.jpg`;
export const KOREA_INVESTMENT_AC_PRODUCT_IMAGE_2_URL = `${IMAGE_STORAGE_BASE_URL}/KIA_product_02.jpg`;
export const KOREA_INVESTMENT_AC_PRODUCT_IMAGE_3_URL = `${IMAGE_STORAGE_BASE_URL}/KIA_product_03.jpg`;
export const KOREA_INVESTMENT_AC_PRODUCT_IMAGE_4_URL = `${IMAGE_STORAGE_BASE_URL}/KIA_product_04.jpg`;
export const KOREA_INVESTMENT_AC_PRODUCT_IMAGE_5_URL = `${IMAGE_STORAGE_BASE_URL}/KIA_product_05.jpg`;
// 한국투자AC 브랜드 페이지 아이콘들
export const HOME_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/home-icon.png`;
export const HANDSHAKE_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/handshake-icon.png`;

// 랜딩페이지 배경 이미지
export const LANDING_BG_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/landing-bg-image.png`;

// 케어라벨 목업 텍스타일 이미지
// 3200 (White)
export const CARE_LABEL_TEXTILE_3200_WHITE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--3200--white.webp`;
export const CARE_LABEL_TEXTILE_3200_WHITE_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--3200--white--filter.webp`;
// Nylon Cross (White)
export const CARE_LABEL_TEXTILE_NYLON_CROSS_WHITE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--nylon-cross--white.webp`;
export const CARE_LABEL_TEXTILE_NYLON_CROSS_WHITE_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--nylon-cross--white--filter.webp`;
// Poly (White)
export const CARE_LABEL_TEXTILE_POLY_WHITE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--poly--white.webp`;
export const CARE_LABEL_TEXTILE_POLY_WHITE_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--poly--white--filter.webp`;
// Sticker (White)
export const CARE_LABEL_TEXTILE_STICKER_WHITE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--sticker--white.webp`;
export const CARE_LABEL_TEXTILE_STICKER_WHITE_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--sticker--white--filter.webp`;
// Urethane (White)
export const CARE_LABEL_TEXTILE_URETHANE_WHITE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--ure--white.webp`;
export const CARE_LABEL_TEXTILE_URETHANE_WHITE_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--ure--white--filter.webp`;
// Urethane (Transparent)
export const CARE_LABEL_TEXTILE_URETHANE_TRANSPARENT_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--ure--transparent.webp`;
export const CARE_LABEL_TEXTILE_URETHANE_TRANSPARENT_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--ure--transparent--filter.webp`;
// Satin (White)
export const CARE_LABEL_TEXTILE_SATIN_WHITE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--satin--white.webp`;
export const CARE_LABEL_TEXTILE_SATIN_WHITE_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--satin--white--filter.webp`;
// Satin (Black)
export const CARE_LABEL_TEXTILE_SATIN_BLACK_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--satin--black.webp`;
export const CARE_LABEL_TEXTILE_SATIN_BLACK_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--satin--black--filter.webp`;
// PT (Black)
export const CARE_LABEL_TEXTILE_PT_BLACK_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--pt--black.webp`;
export const CARE_LABEL_TEXTILE_PT_BLACK_FILTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/textile--pt--black--filter.webp`;

// 케어라벨 파일 업로드 가이드 이미지
export const CARE_LABEL_PDF_UPLOAD_GUIDE_IMAGE_URL_1 = `${IMAGE_STORAGE_BASE_URL}/pdf_upload_guideline_screen_01.webp`;
export const CARE_LABEL_PDF_UPLOAD_GUIDE_IMAGE_URL_2 = `${IMAGE_STORAGE_BASE_URL}/pdf_upload_guideline_screen_02.webp`;
export const CARE_LABEL_PDF_UPLOAD_GUIDE_IMAGE_URL_3 = `${IMAGE_STORAGE_BASE_URL}/pdf_upload_guideline_screen_03.webp`;
export const CARE_LABEL_PDF_UPLOAD_GUIDE_IMAGE_URL_4 = `${IMAGE_STORAGE_BASE_URL}/pdf_upload_guideline_screen_04.webp`;
export const CARE_LABEL_PDF_UPLOAD_GUIDE_IMAGE_URL_5 = `${IMAGE_STORAGE_BASE_URL}/pdf_upload_guideline_screen_05.webp`;
export const CARE_LABEL_PDF_UPLOAD_GUIDE_IMAGE_URL_6 = `${IMAGE_STORAGE_BASE_URL}/pdf_upload_guideline_screen_06.webp`;
export const CARE_LABEL_PDF_UPLOAD_GUIDE_IMAGE_URL_7 = `${IMAGE_STORAGE_BASE_URL}/pdf_upload_guideline_screen_07.webp`;

// 랜딩페이지 배경색
export const LANDING_BG_COLOR = `${IMAGE_STORAGE_BASE_URL}/bg_dot_pattern.png`;

// 랜딩페이지(홈) 배너 이미지
export const LANDING_ICON_DATA_URL = `${IMAGE_STORAGE_BASE_URL}/main_data.png`;
export const LANDING_ICON_SECURITY_URL = `${IMAGE_STORAGE_BASE_URL}/main_security.png`;
export const LANDING_ICON_COOPERATION_URL = `${IMAGE_STORAGE_BASE_URL}/main_cooperation.png`;

// 랜딩페이지(홈) 섹션 이미지
export const LANDING_SECTION_HOME_IMAGE_URL_1 = `${IMAGE_STORAGE_BASE_URL}/main_home_section_01.png`;
export const LANDING_SECTION_HOME_IMAGE_URL_2 = `${IMAGE_STORAGE_BASE_URL}/main_home_section_02.png`;
export const LANDING_SECTION_HOME_IMAGE_URL_3 = `${IMAGE_STORAGE_BASE_URL}/main_home_section_03.png`;
export const LANDING_SECTION_HOME_IMAGE_URL_4 = `${IMAGE_STORAGE_BASE_URL}/main_home_section_04.png`;
export const LANDING_SECTION_HOME_IMAGE_URL_5 = `${IMAGE_STORAGE_BASE_URL}/main_home_section_05.png`;
export const LANDING_SECTION_HOME_IMAGE_URL_6 = `${IMAGE_STORAGE_BASE_URL}/main_home_section_06.png`;

// 랜딩페이지(홈) 마퀴 이미지
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_1 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_01.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_2 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_02.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_3 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_03.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_4 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_04.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_5 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_05.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_6 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_06.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_7 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_07.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_8 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_08.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_9 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_09.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_10 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_10.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_11 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_11.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_12 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_12.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_13 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_13.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_14 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_14.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_15 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_15.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_16 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_16.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_17 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_17.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_18 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_18.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_19 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_19.webp`;
export const LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_20 = `${IMAGE_STORAGE_BASE_URL}/marquee_logo_20.webp`;

// 랜딩페이지(인쇄 라벨) 섹션 이미지
export const LANDING_SECTION_PRINT_IMAGE_URL_1 = `${IMAGE_STORAGE_BASE_URL}/main_print_section_01.png`;
export const LANDING_SECTION_PRINT_IMAGE_URL_2 = `${IMAGE_STORAGE_BASE_URL}/main_print_section_02.png`;
export const LANDING_SECTION_PRINT_IMAGE_URL_3 = `${IMAGE_STORAGE_BASE_URL}/main_print_section_03.png`;
export const LANDING_SECTION_PRINT_IMAGE_URL_4 = `${IMAGE_STORAGE_BASE_URL}/main_print_section_04.png`;
export const LANDING_SECTION_PRINT_IMAGE_URL_5 = `${IMAGE_STORAGE_BASE_URL}/main_print_section_05.png`;

// 랜딩페이지(디지털 라벨) 섹션 이미지
export const LANDING_SECTION_DIGITAL_IMAGE_URL_1 = `${IMAGE_STORAGE_BASE_URL}/main_digital_section_01.png`;
export const LANDING_SECTION_DIGITAL_IMAGE_URL_2 = `${IMAGE_STORAGE_BASE_URL}/main_digital_section_02.png`;
export const LANDING_SECTION_DIGITAL_IMAGE_URL_3 = `${IMAGE_STORAGE_BASE_URL}/main_digital_section_03.png`;
export const LANDING_SECTION_DIGITAL_IMAGE_URL_4 = `${IMAGE_STORAGE_BASE_URL}/main_digital_section_04.png`;

// 랜딩페이지(디지털 라벨) 체험 이미지
export const LANDING_SECTION_DIGITAL_EXPERIENCE_IMAGE_URL_1 = `${IMAGE_STORAGE_BASE_URL}/main_digital_experience_01.webp`;
export const LANDING_SECTION_DIGITAL_EXPERIENCE_IMAGE_URL_2 = `${IMAGE_STORAGE_BASE_URL}/main_digital_experience_02.webp`;

// 케이라벨 디자인 원단 미리보기
export const CARE_LABEL_DESIGN_PREVIEW_CROSS_WHITE_BLACK_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/CROSS_WHITE--black.webp`;
export const CARE_LABEL_DESIGN_PREVIEW_URE_TRANSPARENT_BLACK_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/URE_TRANSPARENT--black.webp`;
export const CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_GOLD_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/SATIN_BLACK--gold.webp`;
export const CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_SILVER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/SATIN_BLACK--silver.webp`;
export const CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_GRAY_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/SATIN_BLACK--gray.webp`;
export const CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_WHITE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/SATIN_BLACK--white.webp`;

// 케어라벨 디자인 가이드 이미지
export const GUIDE_IMAGES = {
  default: `${IMAGE_STORAGE_BASE_URL}/guide-image-default.svg`,
  kr: [
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-0.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-1.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-2.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-3.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-4.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-5.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-6.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-7.webp`,
  ],
  cn: [
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-0.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-1.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-2.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-3.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-4.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-5.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-6.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-cn-7.webp`,
  ],
  eu: [
    `${IMAGE_STORAGE_BASE_URL}/guide-image-eu-0.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-eu-1.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-eu-2.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-eu-3.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-eu-4.webp`,
  ],
  global: [
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-0.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-1.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-2.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-3.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-4.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-5.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-6.webp`,
    `${IMAGE_STORAGE_BASE_URL}/guide-image-kr-7.webp`,
  ],
} as const;

/**
 * Thug Club
 */
// Thug Club 로고 이미지 (문자)
export const THUG_CLUB_LOGO_LETTER_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/thugclub-logo-letter.svg`;

// Thug Club 로고 이미지 (엠블럼)
export const THUG_CLUB_LOGO_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/thugclub-logo.png`;

// Thug Club 메인 이미지 (배경용)
export const THUG_CLUB_MAIN_BG_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/thugclub-bg-main.jpg`;

// Thug Club 서명 페이지 타이틀 (Certificate)
export const THUG_CLUB_TITLE_CERTIFICATE = `${IMAGE_STORAGE_BASE_URL}/thugclub-title-certificate.webp`;

// 케어라벨 사이즈 미리보기 이미지
export const CARE_LABEL_SIZE_PREVIEW_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/care-label-size-preview.webp`;

// 디지털 라벨 샘플 아이폰 목업 이미지
export const DPP_SAMPLE_IPHONE_URL = `${IMAGE_STORAGE_BASE_URL}/dpp-sample-iphone.webp`;
export const DPP_SAMPLE_TOOL_TIP_URL = `${IMAGE_STORAGE_BASE_URL}/sample-tool-tip.webp`;

// 사진 업로드 디폴트 이미지
export const PICTURE_URL = `${IMAGE_STORAGE_BASE_URL_2}/picture.png`;

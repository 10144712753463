import { Icon } from '@components/@shared';
import { Button } from '@components/admin/@shared';
import styled from 'styled-components';

const CUSTOMER_SERVICE_INFO = [
  {
    label: '전화번호',
    value: '070-7782-7601',
  },
  {
    label: '이메일',
    value: 'careid@careid.center',
  },
  {
    label: '영업시간',
    value: '10:00 - 19:00',
  },
];

const CHAT_URL = 'https://pf.kakao.com/_IxdBxdn';

interface CustomerServiceBoxProps {
  onClose: () => void;
}

function CustomerServiceBox({ onClose }: CustomerServiceBoxProps) {
  const handleNavigateToChannelTalk = (e: React.MouseEvent) => {
    e.stopPropagation();
    const windowFeatures = 'width=600,height=800,scrollbars=yes,resizable=yes';
    window.open(CHAT_URL, '_blank', windowFeatures);
  };

  return (
    <CustomerServiceContainer>
      <CustomerServiceHeader>
        <CustomerServiceTitle>고객센터</CustomerServiceTitle>
        <CustomerServiceCloseButton onClick={onClose}>
          <Icon name="X" fill="black" width={12} height={12} />
        </CustomerServiceCloseButton>
      </CustomerServiceHeader>
      <CustomerServiceContent>
        {CUSTOMER_SERVICE_INFO.map((info) => (
          <CustomerServiceInfo key={info.label}>
            <CustomerServiceLabel>{info.label}</CustomerServiceLabel>
            <CustomerServiceValue>{info.value}</CustomerServiceValue>
          </CustomerServiceInfo>
        ))}
      </CustomerServiceContent>
      <CustomButton onClick={handleNavigateToChannelTalk}>
        1:1 채팅 문의
      </CustomButton>
    </CustomerServiceContainer>
  );
}

interface CustomerServiceButtonProps {
  onClick?: () => void;
}

function CustomerServiceButton({ onClick }: CustomerServiceButtonProps) {
  return (
    <StyledButton onClick={onClick}>
      <Icon name="Headphone" fill="white" />
    </StyledButton>
  );
}

export default Object.assign(CustomerServiceBox, {
  Button: CustomerServiceButton,
});

const CustomerServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  padding: 12px;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;

const CustomerServiceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CustomerServiceTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

const CustomerServiceCloseButton = styled.button`
  background-color: ${({ theme }) => theme.colors.gray50};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomerServiceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const CustomerServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const CustomerServiceLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray400};
`;

const CustomerServiceValue = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const CustomButton = styled.button`
  padding: 0 16px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  word-break: keep-all;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};

  /* 버튼 호버 시 색상 변경 */
  &:not(:disabled):hover {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.gray100};
  }
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border: none;
`;

import { useEffect, MouseEvent, PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { allowScroll, preventScroll } from '@lib/utils';

const portalElement = document.getElementById('modal') as HTMLElement;

interface ModalProps extends PropsWithChildren {
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  onCloseModal: () => void;
}

function Modal({
  children,
  primaryButtonLabel,
  secondaryButtonLabel,
  onClickPrimaryButton,
  onClickSecondaryButton,
  onCloseModal,
}: ModalProps) {
  const [scrollY, setScrollY] = useState<string>('0');

  const handleModelClose = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;

    onCloseModal();
  };

  const handlePrimaryButtonClick = () => {
    onClickPrimaryButton?.();
    onCloseModal();
  };

  const handleSecondaryButtonClick = () => {
    onClickSecondaryButton?.();
    onCloseModal();
  };

  // 모달이 띄워졌을 때 스크롤 방지 (화면 고정)
  useEffect(() => {
    const prevScrollY = preventScroll();
    setScrollY(`${window.scrollY}px`);

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  return createPortal(
    <Wrapper onClick={handleModelClose} $scrollY={scrollY}>
      <Container>
        <Dialog>{children}</Dialog>
        <ButtonsContainer>
          <Button
            type="button"
            name="btn-secondary"
            onClick={handleSecondaryButtonClick}
          >
            {secondaryButtonLabel || '취소'}
          </Button>
          {onClickPrimaryButton && (
            <Button
              type="button"
              name="btn-primary"
              onClick={handlePrimaryButtonClick}
            >
              {primaryButtonLabel || '확인'}
            </Button>
          )}
        </ButtonsContainer>
      </Container>
    </Wrapper>,
    portalElement
  );
}

interface DialogTitleProps {
  title: string;
}

function DialogTitle({ title }: DialogTitleProps) {
  return <ModalTitle>{title}</ModalTitle>;
}

export default Object.assign(Modal, {
  DialogTitle,
});

interface WrapperProps {
  $scrollY: string;
}

interface DialogProps {
  warning?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: ${(props) => props.$scrollY};
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(28, 28, 28, 0.8);
  z-index: 9998;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  width: 80%;
  max-width: 340px;
  min-height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  overflow: hidden;
  cursor: default;
  z-index: 9999;
`;

const Dialog = styled.dialog<DialogProps>`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  font-size: 14px;
  font-weight: 400;
`;

const ModalTitle = styled.h3`
  font-size: ${(props) => props.theme.fonts.size.md};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  margin-bottom: ${(props) => props.theme.spaces.xxxs};
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Button = styled.button`
  padding: ${(props) => props.theme.spaces.xs};
  width: 100%;
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  color: ${(props) => props.theme.colors.gray600};
  background-color: ${(props) => props.theme.colors.gray50};

  &:last-child {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.gray900};
  }
`;

import styled from 'styled-components';
import Layout from '../Layout';
import { media } from '@lib/styles/breakpoints';
import {
  HOME_MOBILE_VIDEO_URL,
  HOME_VIDEO_URL,
} from '@lib/constants/videoUrls';
import {
  accessTokenAtomWithPersistence,
  roleAtomWithPersistence,
} from '@store/login';
import { useAtom } from 'jotai';
import { useModal } from '@lib/hooks';
import { LandingModal } from '../LandingModal/LandingModal';
import { useNavigate } from 'react-router-dom';
import { PARTNER_PAGE_PATH } from '@lib/constants/admin/route';

export default function Section01() {
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const [role] = useAtom(roleAtomWithPersistence);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (role === 'partner') {
      navigate(`${PARTNER_PAGE_PATH.index}/${PARTNER_PAGE_PATH.list}`);
      return;
    }

    if (role === 'brand') {
      handleModalOpen();
      return;
    }
  };

  return (
    <>
      {isModalOpen && <LandingModal onClose={handleModalClose} />}
      <Layout>
        <StyledMain>
          <DesktopVideo
            autoPlay
            muted
            loop
            playsInline
            // webkit-playsinline="true"
            preload="auto"
          >
            <source src={HOME_VIDEO_URL} type="video/mp4" />
          </DesktopVideo>
          <MobileVideo
            autoPlay
            muted
            loop
            playsInline
            // webkit-playsinline="true"
            preload="auto"
          >
            <source src={HOME_MOBILE_VIDEO_URL} type="video/mp4" />
          </MobileVideo>
          <MainInner>
            <MainContent>
              <MainTitle>
                옷의 가치를 높이는
                <br />
                가장 손쉬운 솔루션
              </MainTitle>
              <MainDescription>
                우리 브랜드 제품을 디지털화하여
                <br />
                고객과 브랜드의 가치를 공유하세요.
              </MainDescription>
              <ButtonsContainer>
                {accessToken ? (
                  <StyledButton type="button" onClick={handleButtonClick}>
                    시작하기
                  </StyledButton>
                ) : null}
              </ButtonsContainer>
            </MainContent>
          </MainInner>
        </StyledMain>
      </Layout>
    </>
  );
}

const DesktopVideo = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  ${media.mobile} {
    display: none;
  }
`;

const MobileVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  display: none;

  ${media.mobile} {
    display: block;
  }
`;

const StyledMain = styled.main`
  margin-top: 128px;
  margin-bottom: 64px;
  height: calc(100vh - 256px);
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 40px;
  padding: 40px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background-color: white;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 30%;
    width: 150px;
    height: 100%;
    background: linear-gradient(to right, white, transparent);
    z-index: 1;
  }

  ${media.mobile} {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh;
    border: none;
    border-radius: 0;
    padding: 0;

    &::before,
    &::after {
      display: none;
    }
  }
`;

const MainInner = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;
`;

const MainContent = styled.div`
  align-items: flex-start;
  border-radius: 40px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 0 80px;
  position: relative;
  width: 100%;

  ${media.tablet} {
    padding: 0 20px;
  }

  ${media.mobile} {
    justify-content: flex-start;
    padding: 100px 10px 0;
  }
`;

const MainTitle = styled.h1`
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 1px;

  ${media.tablet} {
    font-size: 48px;
  }

  ${media.mobile} {
    font-size: 32px;
  }
`;

const MainDescription = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.gray400};
`;

const ButtonsContainer = styled.div`
  width: fit-content;
  display: flex;
  gap: 24px;
`;

const StyledButton = styled.button`
  width: 120px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  padding: 12px 12px 12px 16px;
  border-radius: 10px;
  font-weight: 500;
  transition: all 0.3s ease;

  a {
    color: inherit;
    text-decoration: none;
  }

  /* Hovered */
  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.black};

    a {
      color: inherit;
    }
  }

  ${media.mobile} {
    display: none;
  }
`;

import { ModalContainer } from '../../@shared/molecules';
import styled from 'styled-components';
import { IconWrapper } from '@components/@shared';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DemoFormData, demoSchema } from '@lib/apis/admin/@schema/demo';
import { Button } from '@components/admin/@shared';
import { InputContainer } from '@components/@shared';
import { useModal } from '@lib/hooks';
import { LandingModalSuccess } from './LandingModalSuccess';

interface LandingModalFormProps {
  onClose: () => void;
}

export function LandingModalForm({ onClose }: LandingModalFormProps) {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const methods = useForm<DemoFormData>({
    resolver: zodResolver(demoSchema),
    mode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  const handleDemoSubmit = (data: DemoFormData) => {
    handleModalOpen();
  };

  return (
    <>
      {isModalOpen && <LandingModalSuccess onClose={handleModalClose} />}
      <ModalContainer onCloseModal={onClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleDemoSubmit)}>
            <Container>
              <Content>
                <BackIconButton onClick={onClose}>
                  <IconWrapper
                    iconName="ChevronLeft"
                    $width={32}
                    $height={32}
                    fill="black"
                    $borderWidth={1}
                  />
                </BackIconButton>
                <Title>서비스 체험판 미리 신청하기</Title>
                {/* 이메일 필드 */}
                <InputContainer
                  inputName="email"
                  label="이메일"
                  errorMessage={errors.email?.message}
                  // direction="column"
                  flexDirection="column"
                  isDirectionRow={false}
                >
                  <InputContainer.Input
                    inputName="email"
                    placeholder="이메일을 입력해주세요."
                    {...register('email')}
                  />
                </InputContainer>
                {/* 이름 필드 */}
                <InputContainer
                  inputName="name"
                  label="이름"
                  errorMessage={errors.name?.message}
                  // direction="column"
                  flexDirection="column"
                  isDirectionRow={false}
                >
                  <InputContainer.Input
                    inputName="name"
                    placeholder="이름을 입력해주세요."
                    {...register('name')}
                  />
                </InputContainer>
                {/* 전화번호 필드 */}
                <InputContainer
                  inputName="phone"
                  label="전화번호"
                  errorMessage={errors.phone?.message}
                  // direction="column"
                  flexDirection="column"
                  isDirectionRow={false}
                >
                  <InputContainer.Input
                    inputName="phone"
                    placeholder="전화번호를 입력해주세요."
                    {...register('phone')}
                  />
                </InputContainer>
              </Content>
              <Button label="제출하기" type="submit" disabled={!isValid} />
            </Container>
          </form>
        </FormProvider>
      </ModalContainer>
    </>
  );
}

const Container = styled.div`
  width: 540px;
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
`;

const BackIconButton = styled.button`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
`;

import { useQuery } from '@tanstack/react-query';
import { userAxios } from './@core';
import { DppLevelFiveData } from '@customTypes/user';
import { DppDetailData } from '@customTypes/user/dpp-v2';
import { useParams } from 'react-router-dom';

// /**
//  * 제품 상세 조회 API
//  */
// const fetchProductDetail = async (
//   productId: string | undefined
// ): Promise<DppLevelFiveData> => {
//   const res = await userAxios.get(`/product/${productId}`);

//   return res?.data;
// };

// export const useProductDetail = (productId: string | undefined) => {
//   return useQuery({
//     queryKey: ['productDetail'],
//     queryFn: () => fetchProductDetail(productId),
//     enabled: !!productId,
//   });
// };

/**
 * 제품 상세 조회 API
 */
const fetchProductDetail = async (
  productId: string | undefined
): Promise<DppDetailData> => {
  const res = await userAxios.get(`/product/dpp/${productId}`);

  return res?.data;
};

export const useProductDetail = (id?: string) => {
  const { productId } = useParams();

  return useQuery({
    queryKey: ['productDetail'],
    queryFn: () => fetchProductDetail(id || productId),
    enabled: !!(id || productId),
  });
};
